import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'
import { func, object } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import {
  fetchPriceComplianceGeographyCompliance,
  fetchPriceComplianceGeographyFilters
} from 'store/priceCaptureCompliance/actions'
import { getPriceComplianceGeography, getPriceComplianceGeographyFilters } from 'store/priceCaptureCompliance/selectors'

import Card from 'components/card'
import DataTable from 'components/DataTable/DataTable'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import WideDataTable, { createColumns } from 'components/DataTable/WideDataTable'
import EmptyState from 'components/EmptyState'
import Pagination from 'components/Pagination'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES, DEFAULT_TABLE_PAGE_SIZE, SECTOR_LEVELS, SORTING_DIRECTION } from 'utils/constants'
import { formatCompactNumber, formatCurrency, formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage, sortDataTableBy } from 'utils/helpers'

import GeographyFilterBuilder from '../GeographyFilterBuilder'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'
import StoreInfoTooltip from '../StoreInfoTooltip'

const createOptions = ({ filters, special }) => {
  let options = []
  if (special && special === 'counts') {
    options = filters.map((filter) => {
      switch (filter) {
        case 1:
          return { label: 'Single', value: filter }
        case 2:
          return { label: '2 Pack', value: filter }
        default:
          return { label: filter, value: filter }
      }
    })
  } else if (special && special === 'types') {
    options = filters.map((filter) => ({ label: `Pack of ${filter}`, value: filter }))
  } else {
    if (typeof filters[0] === 'string') {
      options = filters.map((filter) => ({ label: filter, value: filter }))
    } else {
      options = filters.map((filter) => ({ label: filter.name, value: filter.id }))
    }
  }

  return sortBy(options, 'label')
}
export const PRICING_STRATEGY_CONDITION_TYPES = {
  NONE: 'None',
  BENCHMARK: 'Benchmark',
  VS_CEILING: 'Vs. Ceiling',
  LPP: 'LPP'
}

const GeographyCompliance = ({
  span,
  fetchPriceComplianceGeographyCompliance,
  fetchPriceComplianceGeographyFilters
}) => {
  const { translate } = useContext(LangContext)

  const SUB_COLUMNS = [
    {
      title: 'Compl.',
      field: 'compliantCustomers'
    },
    {
      title: 'Non-compl.',
      field: 'notCompliantCustomers'
    },
    {
      title: 'Compl. %',
      field: 'percentCompliant'
    },
    {
      title: 'Price',
      field: 'averagePrice'
    }
  ]

  const { sectorType, sectorId } = useParams()
  const { currentProductType } = useContext(SectorContext)

  const [error, setError] = useState()
  const [geography, setGeography] = useState('territory')
  // filters
  const defaultFiltersState = {
    tier: 'all',
    channel: 'all',
    ownershipType: 'all',
    banner: 'all',
    headoffice: 'all',
    packType: 'all',
    productName: 'all',
    packCount: 'all',
    strategyType: 'all',
    strategyName: 'all'
  }
  const [filters, setFilters] = useState(defaultFiltersState)
  const [page, setPage] = useState(1)

  const [productLevel, setProductLevel] = useState(['brand', 'brand_variant', 'sku'].includes(geography))

  const [sortBy, setSortBy] = useState({
    column: productLevel ? 'geo' : 'name',
    order: productLevel ? SORTING_DIRECTION.DESC : SORTING_DIRECTION.ASC
  })

  const handleSort = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  const geographies = useSelector((state) => getPriceComplianceGeography(state))

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const geographyDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.PRICE_COMPLIANCE_GEOGRAPHY, {
    sectorType,
    sectorId,
    geography,
    productType: currentProductType,
    filters,
    offset,
    sortBy: sortBy.column,
    direction: sortBy.order
  })

  const isGeographyDataLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: geographyDataKey }))
  const filtersDataKey = createDataKey(
    DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.PRICE_COMPLIANCE_GEOGRAPHY_FILTERS,
    { sectorType, sectorId, productType: currentProductType }
  )
  const areFiltersLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: filtersDataKey }))
  const fetchedFilters = useSelector((state) =>
    getPriceComplianceGeographyFilters(state, { productType: currentProductType })
  )

  const {
    channelOptions,
    ownershipOptions,
    headofficeOptions,
    bannerOptions,
    packTypesOptions,
    packCountOptions,
    strategyTypeOptions,
    tierOptions,
    productNameOptions,
    strategyNameOptions
  } = {
    channelOptions: createOptions({
      filters: fetchedFilters.channels || []
    }),
    ownershipOptions: createOptions({
      filters: fetchedFilters.ownershipTypes || []
    }),
    headofficeOptions: createOptions({
      filters: fetchedFilters.headoffices || []
    }),
    bannerOptions: createOptions({
      filters: fetchedFilters.banners || []
    }),
    packTypesOptions: createOptions({
      filters: fetchedFilters.packTypes || [],
      special: 'types'
    }),
    packCountOptions: createOptions({
      filters: fetchedFilters.packCounts || [],
      special: 'counts'
    }),
    strategyTypeOptions: createOptions({
      filters: fetchedFilters?.strategyTypes || []
    }),
    productNameOptions: createOptions({
      filters: fetchedFilters?.productNames || [],
      special: 'types'
    }),
    tierOptions: createOptions({
      filters: fetchedFilters?.tiers || []
    }),
    strategyNameOptions: createOptions({
      filters: fetchedFilters?.conditionNames || []
    })
  }

  const geographyData = useMemo(() => {
    const {
      channel,
      headoffice,
      banner,
      packType,
      packCount,
      strategyType,
      ownershipType,
      tier,
      productName,
      strategyName
    } = filters
    const results =
      geographies?.[
        `geography-${geography}-${currentProductType}-${channel}-${headoffice}-${banner}-${packType}-${packCount}-${strategyType}-${ownershipType}-${tier}-${productName}-${strategyName}-${offset}-${sortBy.column}-${sortBy.order}`
      ] || {}

    if (isEmpty(results)) return []

    return Object.values(results).map((complianceGeo) => {
      if (geography === SECTOR_LEVELS.REGION) {
        return {
          linkTo: `/${SECTOR_LEVELS.REGION}/${complianceGeo.id}/pace/amplify/price-compliance`,
          ...complianceGeo
        }
      }
      if (geography === SECTOR_LEVELS.DISTRICT) {
        return {
          linkTo: `/${SECTOR_LEVELS.DISTRICT}/${complianceGeo.id}/pace/amplify/price-compliance`,
          ...complianceGeo
        }
      }
      if (geography === SECTOR_LEVELS.TERRITORY) {
        return {
          linkTo: `/${SECTOR_LEVELS.TERRITORY}/${complianceGeo.id}/pace/amplify/price-compliance`,
          ...complianceGeo
        }
      }
      if (geography === SECTOR_LEVELS.CUSTOMER) {
        return {
          linkTo: `/${SECTOR_LEVELS.CUSTOMER}/${complianceGeo.id}/pace/amplify/price-compliance`,
          ...complianceGeo
        }
      }

      if (sectorType === SECTOR_LEVELS.CUSTOMER && geography === 'sku') {
        return {
          linkTo: `/${SECTOR_LEVELS.CUSTOMER}/${sectorId}/actions/pricing/${complianceGeo.id}`,
          ...complianceGeo
        }
      }

      return {
        linkTo: null,
        ...complianceGeo
      }
    })
  }, [geography, filters, offset, sectorId, sectorType, currentProductType, geographies, sortBy])

  const displayPrice = useMemo(() => {
    return sectorType === SECTOR_LEVELS.CUSTOMER && geography === 'sku'
  })

  const strategies = useMemo(() => {
    const strats = []

    for (const data of geographyData) {
      for (const [id, strat] of Object.entries(data.strategies)) {
        const stratAlreadyExists = strats.some((s) => s.id === id)
        if (!stratAlreadyExists) strats.push({ id, name: strat.conditionName })
      }
    }

    return orderBy(strats, 'id', 'desc')
  }, [geographyData])

  const rows = useMemo(() => {
    if (['brand', 'brand_variant', 'sku'].includes(geography)) {
      if (geography === 'sku' && sectorType === 'customer') {
        const productRows = []
        geographyData.map((geo) => {
          return Object.values(geo.strategies).forEach((strat) => {
            const {
              averagePrice,
              averageGap,
              conditionName,
              percentCompliant,
              conditionType,
              notCompliantCustomers,
              benchmarkName
            } = strat
            const displayName = `${geo.name} - ${geo.id}`
            productRows.push({
              id: geo.id,
              geo: <GeoTableLineHeader name={displayName} linkTo={geo.linkTo} />,
              percentCompliant: formatPercent(percentCompliant) || '',
              averagePrice: formatCurrency(averagePrice) || '',
              averageGap: conditionType === 'NONE' ? '-' : formatCurrency(averageGap) || '',
              benchmarkName: ['NONE', 'VS_CEILING'].includes(conditionType) ? '-' : benchmarkName || '-',
              benchmarkPrice: conditionType === 'NONE' ? '-' : formatCurrency(averagePrice - averageGap) || '',
              conditionName,
              captureStatus: notCompliantCustomers > 0 ? 'Compliant' : 'Non Compliant',
              conditionType: PRICING_STRATEGY_CONDITION_TYPES[conditionType]
            })
          })
        })
        return productRows
      }
      const productRows = []
      geographyData.map((geo) => {
        return Object.values(geo.strategies).forEach((strat) => {
          const {
            averagePrice,
            compliantCustomers,
            conditionName,
            notCompliantCustomers,
            percentCompliant,
            conditionType
          } = strat
          const displayName = geography === 'sku' ? `${geo.name} - ${geo.id}` : geo.name
          productRows.push({
            id: geo.id,
            geo: <GeoTableLineHeader name={displayName} linkTo={geo.linkTo} />,
            compliantCustomers,
            notCompliantCustomers,
            percentCompliant: formatPercent(percentCompliant) || '',
            averagePrice: formatCurrency(averagePrice) || '',
            conditionName,
            conditionType: PRICING_STRATEGY_CONDITION_TYPES[conditionType]
          })
        })
      })
      return productRows
    }
    return geographyData.reduce((acc, data) => {
      const value = {
        geo:
          geography === SECTOR_LEVELS.CUSTOMER ? (
            <StoreInfoTooltip
              displayName={data.name}
              customerName={data.name}
              erp={data.id}
              address={data.address}
              ownershipType={data.ownershipType}
              linkTo={data.linkTo}
            />
          ) : (
            <GeoTableLineHeader name={data.name} linkTo={data.linkTo} />
          )
      }

      strategies.forEach((strat) => {
        const linkedStrategy = data.strategies?.[strat.id] || {}
        value[camelCase(`${strat.id}_compliantCustomers`)] =
          formatCompactNumber(linkedStrategy?.compliantCustomers, { shouldStripIfInteger: true }) || ''
        value[camelCase(`${strat.id}_notCompliantCustomers`)] =
          formatCompactNumber(linkedStrategy?.notCompliantCustomers, { shouldStripIfInteger: true }) || ''
        value[camelCase(`${strat.id}_percentCompliant`)] = formatPercent(linkedStrategy?.percentCompliant) || ''
        if (displayPrice)
          value[camelCase(`${strat.id}_averagePrice`)] = formatCurrency(linkedStrategy?.averagePrice) || ''
      })
      acc.push(value)
      return acc
    }, [])
  }, [geographyData, strategies])

  const prevPage = () => {
    if (page === 0) return

    onPageChange(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(rows) || rows.length < DEFAULT_TABLE_PAGE_SIZE) return
    onPageChange(page + 1)
  }

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  async function fetchComplianceData({ newFilters, newPage, newGeography, newSector, newSectorId, newProductType }) {
    const activePage = newPage || page

    const newValues = {
      geography: newGeography || geography,
      filters: newFilters || filters,
      offset: activePage * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE,
      sectorType: newSector || sectorType,
      sectorId: newSectorId || sectorId,
      productType: newProductType || currentProductType,
      sortBy: sortBy.column,
      direction: sortBy.order
    }

    fetchPriceComplianceGeographyCompliance({
      dataKey: createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.PRICE_COMPLIANCE_GEOGRAPHY, newValues),
      limit: DEFAULT_TABLE_PAGE_SIZE,
      ...newValues
    })
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }

  async function fetchComplianceFilters() {
    fetchPriceComplianceGeographyFilters({
      dataKey: createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.PRICE_COMPLIANCE_GEOGRAPHY_FILTERS, {
        sectorType,
        sectorId,
        productType: currentProductType
      }),
      sectorType,
      sectorId,
      productType: currentProductType
    })
      .then(() => isMounted.current && setError())
      .catch((e) => isMounted.current && setError(e))
  }

  async function onFilterChange(newValue) {
    setPage(1)
    const newFilters = { ...filters, ...newValue }
    setFilters(newFilters)
    await fetchComplianceData({ newFilters, newPage: 1 })
  }

  async function onPageChange(newPage) {
    setPage(newPage)
    await fetchComplianceData({ newPage })
  }

  async function onGeographyChange(newGeography) {
    setPage(1)
    setGeography(newGeography)
    setProductLevel(['brand', 'brand_variant', 'sku'].includes(newGeography))

    await fetchComplianceData({ newPage: 1, newGeography })
  }

  useEffect(() => {
    setPage(1)
    setFilters(defaultFiltersState)
    if (sectorType && sectorId) {
      fetchComplianceData({
        newFilters: defaultFiltersState,
        newPage: 1,
        newProductType: currentProductType,
        newSector: sectorType,
        newSectorId: sectorId
      })
      fetchComplianceFilters()
    }
  }, [sectorType, sectorId, currentProductType])

  useEffect(() => {
    setSortBy({
      column: productLevel ? 'geo' : 'name',
      order: productLevel ? SORTING_DIRECTION.DESC : SORTING_DIRECTION.ASC
    })
  }, [productLevel])

  useEffect(() => {
    setPage(1)
    if (sectorType && sectorId) {
      fetchComplianceData({ newPage: 1 })
    }
  }, [sortBy])

  useEffect(() => {
    if (sectorType && sectorId) {
      fetchComplianceData({})
      fetchComplianceFilters()
      fetchPriceComplianceGeographyFilters({
        dataKey: filtersDataKey,
        sectorType,
        sectorId,
        productType: currentProductType
      })
        .then(() => isMounted.current && setError())
        .catch((e) => isMounted.current && setError(e))
    }
  }, [])

  const getContent = () => {
    if (isGeographyDataLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    if (!rows || !rows.length) return <EmptyState title="Nothing to display" />

    let subColumns = SUB_COLUMNS
    if (!displayPrice) {
      subColumns = subColumns.filter((sub) => sub.field !== 'averagePrice')
    }

    if (['brand', 'brand_variant', 'sku'].includes(geography)) {
      // here is where the customer product level is rendered

      if (geography === 'sku' && sectorType === 'customer') {
        const cols = createColumns([
          {
            title: translate(displayOptTableLabels(geography)),
            field: 'geo'
          },
          {
            title: translate('app.strategyName'),
            field: 'conditionName'
          },
          {
            title: translate('app.strategyType'),
            field: 'conditionType'
          },
          {
            title: translate('app.price'),
            field: 'averagePrice'
          },
          {
            title: translate('app.benchmarkPrice'),
            field: 'benchmarkPrice'
          },
          {
            title: translate('app.benchmarkProduct'),
            field: 'benchmarkName'
          },
          {
            title: translate('common.status'),
            field: 'captureStatus'
          },
          {
            title: translate('sellIn.gap'),
            field: 'averageGap'
          }
        ])
        return (
          <DataTable
            columns={cols.map(({ title, ...col }) => ({ headerName: title, ...col }))}
            rows={rows}
            fillContainer
            stickyFirstColumn
            stickyHeaders
            activeColumn={sortBy}
            onColumnClick={handleSort}
          />
        )
      }
      const cols = createColumns([
        {
          title: translate(displayOptTableLabels(geography)),
          field: 'geo'
        },
        {
          title: 'Strategy Name',
          field: 'conditionName'
        },
        {
          title: 'Strategy Type',
          field: 'conditionType'
        },
        ...subColumns
      ])
      return (
        <DataTable
          columns={cols.map(({ title, ...col }) => ({ headerName: title, ...col }))}
          rows={rows}
          fillContainer
          activeColumn={sortBy}
          onColumnClick={handleSort}
        />
      )
    }

    const cols = createColumns([
      {
        title: translate(displayOptTableLabels(geography)),
        field: 'geo'
      },
      ...strategies.map((strat) => ({ title: strat.name, field: strat.name, subColumns }))
    ])

    return (
      <WideDataTable
        columns={cols}
        rows={rows}
        fillContainer
        alignCenter
        alternatingColumns={displayPrice ? 4 : 3}
        //
        activeSort={cols.length === 2}
        activeColumn={sortBy}
        onColumnClick={handleSort}
      />
    )
  }

  const geoFilters = useMemo(
    () => [
      {
        id: 'channel',
        label: translate('app.channel'),
        searchPlaceholder: translate('app.placeholders.searchChannel'),
        options: channelOptions,
        value: filters.channel,
        onChange: (value) => onFilterChange({ channel: value })
      },
      {
        id: 'tier',
        label: translate('app.tier'),
        searchPlaceholder: translate('app.placeholders.searchTier'),
        options: tierOptions,
        value: filters.tier,
        onChange: (value) => onFilterChange({ tier: value })
      },
      {
        id: 'headoffice',
        label: translate('app.headOffice'),
        searchPlaceholder: translate('app.placeholders.searchHeadOffice'),
        options: headofficeOptions,
        value: filters.headoffice,
        onChange: (value) => onFilterChange({ headoffice: value })
      },
      {
        id: 'banner',
        label: translate('app.banner'),
        searchPlaceholder: translate('app.placeholders.searchBanner'),
        options: bannerOptions,
        value: filters.banner,
        onChange: (value) => onFilterChange({ banner: value })
      },
      {
        id: 'packType',
        label: translate('app.packType'),
        searchPlaceholder: translate('app.placeholders.searchPackType'),
        options: packTypesOptions,
        value: filters.packType,
        onChange: (value) => onFilterChange({ packType: value })
      },
      {
        id: 'productName',
        label: translate('app.productName'),
        searchPlaceholder: translate('app.placeholders.searchProductName'),
        options: productNameOptions,
        value: filters.productName,
        onChange: (value) => onFilterChange({ productName: value })
      },
      {
        id: 'packCount',
        label: translate('app.packCount'),
        searchPlaceholder: translate('app.placeholders.searchPackCount'),
        options: packCountOptions,
        value: filters.packCount,
        onChange: (value) => onFilterChange({ packCount: value })
      },
      {
        id: 'strategyType',
        label: translate('app.strategyType'),
        searchPlaceholder: translate('app.placeholders.searchStrategyType'),
        options: strategyTypeOptions,
        value: filters.strategyType,
        onChange: (value) => onFilterChange({ strategyType: value })
      },
      {
        id: 'strategyName',
        label: translate('app.strategyName'),
        searchPlaceholder: translate('app.placeholders.searchStrategyName'),
        options: strategyNameOptions,
        value: filters.strategyName,
        onChange: (value) => onFilterChange({ strategyName: value })
      },
      {
        id: 'ownershipType',
        label: translate('app.ownership'),
        searchPlaceholder: translate('app.placeholders.searchOwnership'),
        options: ownershipOptions,
        value: filters.ownershipType,
        onChange: (value) => onFilterChange({ ownershipType: value })
      }
    ],
    [
      translate,
      channelOptions,
      tierOptions,
      headofficeOptions,
      bannerOptions,
      packTypesOptions,
      productNameOptions,
      packCountOptions,
      strategyTypeOptions,
      strategyNameOptions,
      ownershipOptions,
      filters
    ]
  )

  if (areFiltersLoading) {
    return (
      <Card
        title={`${translate(displayOptTableLabels(geography))} Performance`}
        disclaimer="The KPIs below are calculated using data from the previous day and may not reflect today's price capture"
        span={span}
      >
        <WrappedSpinner icon="spinner" />
      </Card>
    )
  }

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <GeographyToggle
          key="compliance-geography-toggle"
          includeSku
          includeBrandVariant
          includeBrand
          includeOwnership
          geography={geography}
          setGeography={(geo) => onGeographyChange(geo)}
        />,
        <GeographyFilterBuilder
          key="geography-filter-builder"
          filters={geoFilters}
          onFiltersChange={(newFilters) => {
            // Merge with default filters to ensure all keys exist
            const updatedFilters = {
              ...defaultFiltersState,
              ...newFilters
            }
            onFilterChange(updatedFilters)
          }}
        />
      ]}
      actions={
        !isGeographyDataLoading && (rows.length >= DEFAULT_TABLE_PAGE_SIZE || page > 1)
          ? [
              <Pagination
                key="price-compliance-pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disabled={isGeographyDataLoading}
                disableNextButton={rows.length < DEFAULT_TABLE_PAGE_SIZE}
              />
            ]
          : null
      }
    >
      {getContent()}
    </Card>
  )
}

GeographyCompliance.propTypes = {
  span: object,
  fetchPriceComplianceGeographyCompliance: func,
  fetchPriceComplianceGeographyFilters: func
}

export default connect(null, {
  fetchPriceComplianceGeographyCompliance,
  fetchPriceComplianceGeographyFilters
})(GeographyCompliance)
