import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { capitalize } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import { func, object } from 'prop-types'

import LangContext from 'context/LangContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchFilters, fetchGeography } from 'store/extraHub/actions'
import { getCurrentFilters, getCurrentGeographies } from 'store/extraHub/selectors'

import Card from 'components/card'
import DataCombined from 'components/DataTable/DataCombined'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import FilteredDropdown from 'components/FilteredDropdown'
import Pagination from 'components/Pagination'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES, DEFAULT_TABLE_PAGE_SIZE, SECTOR_LEVELS, SORTING_DIRECTION } from 'utils/constants'
import { formatPercent } from 'utils/formatters'
import { createDataKey, sortDataTableBy } from 'utils/helpers'

import GeographyDataTable from '../GeographyDataTable'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'
import StoreInfoTooltip from '../StoreInfoTooltip'

import GeographyContactDetails from './GeographyContactDetails'

const createOptions = (values) => {
  return sortBy(
    values.map((value) => ({ label: value.name, value: value.id })),
    'label'
  )
}

const GeographyTable = ({ span, fetchGeography, fetchFilters }) => {
  const { translate } = useContext(LangContext)
  const { sectorType, sectorId } = useParams()

  const [geography, setGeography] = useState(SECTOR_LEVELS.REGION)
  const [error, setError] = useState()
  const [rows, setRows] = useState([])
  const [filter, setFilter] = useState('all')
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState({ column: 'name', order: SORTING_DIRECTION.DESC })

  const handleSort = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  const handleSetGeography = (newGeography) => {
    const isUserGeographySelected = geography === 'user' || newGeography === 'user'
    if (isUserGeographySelected) setSortBy({ column: 'name', order: SORTING_DIRECTION.DESC }) // Columns are different for User level, so we force a reset to a common column

    setGeography(newGeography)
    setPage(1)
  }

  const handleChangeFilter = (newFilter) => {
    setFilter(newFilter)
    setPage(1)
  }

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const LOCATION_COLUMNS = [
    {
      field: 'name',
      headerName: translate('common.name')
    },
    {
      field: 'erps',
      headerName: translate('app.storeCompleted')
    },
    {
      field: 'storeCompletion',
      headerName: translate('app.storeCompletionRate')
    },
    {
      field: 'users',
      headerName: translate('app.userCompleted')
    },
    {
      field: 'userCompletion',
      headerName: translate('app.userCompletionRate')
    },
    {
      field: 'activeCustomers',
      headerName: translate('app.userEngaged')
    }
  ]

  const USER_COLUMNS = [
    {
      field: 'name',
      headerName: translate('common.name')
    },
    {
      field: 'engaged',
      headerName: translate('app.userEngaged')
    },
    {
      field: 'completedActivities',
      headerName: translate('amplify.extra.activityCompleted')
    },
    {
      field: 'totalActivities',
      headerName: translate('amplify.extra.totalActivities')
    },
    {
      field: 'activityCompletion',
      headerName: translate('app.completionRate')
    },
    { field: 'details', headerName: translate('common.details') }
  ]

  const COLS = geography === 'user' ? USER_COLUMNS : LOCATION_COLUMNS

  const filterDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.EXTRA_HUB.FILTERS, { sectorType, sectorId })
  const geographyDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.EXTRA_HUB.GEOGRAPHY, {
    sectorType,
    sectorId,
    geography,
    activity: filter,
    offset,
    columnSorted: sortBy.column,
    sortDirection: sortBy.order
  })

  const isFiltersLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: filterDataKey }))
  const isGeographyLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: geographyDataKey }))

  const filters = useSelector((state) => getCurrentFilters(state))
  const data = useSelector(
    (state) =>
      getCurrentGeographies(state, { geography, offset, filter, sortBy: sortBy.column, sortDirection: sortBy.order }) ||
      []
  )

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(data) || data.length < DEFAULT_TABLE_PAGE_SIZE) return
    setPage(page + 1)
  }

  // Filter
  useEffect(() => {
    if (sectorType && sectorId) {
      setError(null)
      fetchFilters({ sectorType, sectorId, dataKey: filterDataKey }).catch((error) => setError(error))
    }
  }, [sectorType, sectorId])

  // Table Data
  useEffect(() => {
    setError(null)

    if (geography === 'user' && sectorType !== SECTOR_LEVELS.CUSTOMER) return

    if (sectorType && sectorId) {
      fetchGeography({
        sectorType,
        sectorId,
        geography,
        dataKey: geographyDataKey,
        activity: filter,
        offset,
        sortBy: sortBy.column,
        sortDirection: sortBy.order
      }).catch((error) => setError(error))
    }
  }, [sectorType, sectorId, geography, filter, offset, sortBy])

  const formatLocationGeoData = (row) => {
    const { id, name } = row
    const shouldLinkTo =
      ['region', 'district', 'territory', 'customer'].includes(geography) ||
      (geography === 'sku' && sectorType === SECTOR_LEVELS.CUSTOMER)
    const link = shouldLinkTo ? `/${geography}/${id}/pace/amplify/price-capture` : null

    return {
      name:
        geography === SECTOR_LEVELS.CUSTOMER ? (
          <StoreInfoTooltip
            displayName={name}
            customerName={name}
            erp={id}
            address={row.address}
            ownershipType={row.ownership_type}
            linkTo={link}
          />
        ) : (
          <GeoTableLineHeader name={name} linkTo={link} />
        ),
      erps: (
        <DataCombined
          primary={row.totalCompletedStores.toLocaleString()}
          secondary={`of ${row.totalStores.toLocaleString()}`}
        />
      ),
      storeCompletion: formatPercent(row.storeCompletion),
      users: (
        <DataCombined
          primary={row.totalCompletedUsers.toLocaleString()}
          secondary={`of ${row.activeContacts.toLocaleString()}`}
        />
      ),
      userCompletion: formatPercent(row.userCompletion),
      activeCustomers: row.engagedContacts.toLocaleString()
    }
  }

  const formatUserGeoData = (row) => {
    return {
      name: <GeoTableLineHeader name={row.name} />,
      engaged: row.engaged ? 'YES' : 'NO',
      completedActivities: row.completedActivities,
      totalActivities: row.totalActivities,
      activityCompletion: formatPercent(row.activityCompletion),
      details: <GeographyContactDetails contact={row} />
    }
  }

  // Format Table Data
  useEffect(() => {
    if (isFiltersLoading || isGeographyLoading) return

    const formatRowData = geography === 'user' ? formatUserGeoData : formatLocationGeoData
    const newRows = data.map(formatRowData)

    setRows(newRows)
  }, [data, geography, filter, isFiltersLoading, isGeographyLoading, offset])

  if (isFiltersLoading) {
    return (
      <Card title="Region Performance" span={span}>
        <WrappedSpinner icon="spinner" />
      </Card>
    )
  }

  const activityOptions = [{ label: 'All Campaigns', value: 'all' }].concat(createOptions(filters || []))

  COLS[0].headerName = capitalize(geography)

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <FilteredDropdown
          key="extra-hub-incentive"
          options={activityOptions}
          value={filter}
          onChange={handleChangeFilter}
        />,
        <GeographyToggle
          key="geography-toggle"
          geography={geography}
          setGeography={handleSetGeography}
          includeActivity
          includeCampaign
          includeOwnership
          includeUser={sectorType === 'customer'}
        />
      ]}
      actions={
        !isGeographyLoading && (data.length >= DEFAULT_TABLE_PAGE_SIZE || page > 1)
          ? [
              <Pagination
                key="price-capture-pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disabled={isGeographyLoading}
                disableNextButton={data.length < DEFAULT_TABLE_PAGE_SIZE}
              />
            ]
          : null
      }
    >
      <GeographyDataTable
        isLoading={isGeographyLoading}
        error={error}
        rows={rows}
        columns={COLS}
        onColumnClick={handleSort}
        activeColumn={sortBy}
        unClickableColumns={['details']}
        fillContainer
        stickyHeaders
        stickyFirstColumn
      />
    </Card>
  )
}

GeographyTable.propTypes = {
  span: object,
  fetchGeography: func,
  fetchFilters: func
}

export default connect(null, {
  fetchGeography,
  fetchFilters
})(GeographyTable)
