import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import { useIsMobile } from 'hooks/useIsMobile'
import JsBarcode from 'jsbarcode'
import moment from 'moment'
import { rgba } from 'polished'
import { func } from 'prop-types'
import styled, { css } from 'styled-components'

import LangContext from 'context/LangContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { submitPricingCapture } from 'store/pricings/actions'
import * as pricingSelectors from 'store/pricings/selectors'

import Button from 'components/button/Button'
import Icon from 'components/Icon'
import Input from 'components/Input'
import SegmentControl from 'components/SegmentControl'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, formatUpc } from 'utils/helpers'
import { cn } from 'utils/styling'

import { black, borderColor, greyDark, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius, boxShadow, cover, square } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H2, H3 } from 'styles/typography'

import PricingCardNavArrow from './PricingCardNavArrow'
import PricingCardOverlay from './PricingCardOverlay'

const Container = styled.div`
  ${cover('fixed')};
  background-color: ${rgba(black, 0.65)};
  z-index: 500;
`

const Table = styled.div`
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
`

const Cell = styled.div`
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  padding: ${spacing.large};
  padding-bottom: ${spacing.xxLarge};
`

const Inner = styled.div`
  position: relative;
  padding: ${spacing.large};
  background-color: ${white};
  border-radius: ${borderRadius};
  margin: 0 auto;
  ${boxShadow};

  ${(props) =>
    props.capturesRemaining >= 1 &&
    css`
      &:after {
        content: '';
        position: absolute;
        background-color: ${white};
        left: 6px;
        right: 6px;
        bottom: -6px;
        height: 12px;
        border-radius: ${borderRadius};
        z-index: -1;
        ${boxShadow};
      }
    `}

  ${(props) =>
    props.capturesRemaining >= 2 &&
    css`
      &:before {
        content: '';
        position: absolute;
        background-color: ${white};
        left: 12px;
        right: 12px;
        bottom: -12px;
        height: 12px;
        border-radius: ${borderRadius};
        z-index: -2;
        ${boxShadow};
      }
    `}

  ${media.breakpoint`
    max-width: 380px;
  `};
`

const CodeWrap = styled.div`
  height: 100px;
  margin-bottom: ${spacing.medium};
  margin: 0 auto;
  display: flex;
  justify-content: center;
`

const Code = styled.img`
  height: 100%;
`

const Name = styled(H2)`
  margin-bottom: ${spacing.medium};
  text-align: center;
`

const Description = styled(H3)`
  margin-bottom: ${spacing.medium};
  text-align: center;
`

const Close = styled(Link)`
  position: absolute;
  bottom: 100%;
  right: 0;
  ${square('48px')};
  border-radius: 50%;
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(black, 0.3)};
  margin-bottom: ${spacing.medium};
  transition: background-color ${animationTime} ${animationCurve};

  ${media.breakpoint`
    position: fixed;
    top: ${spacing.large};
    right: ${spacing.large};
    margin: 0;
  `};

  &:hover {
    background-color: ${rgba(black, 0.5)};
  }
`

const Details = styled.div`
  display: flex;
  padding: ${spacing.large} 0;
  border-top: 1px solid ${borderColor};
`

const DetailsColumn = styled.div`
  width: 50%;

  &:first-child {
    padding-right: ${spacing.small};
  }

  &:last-child {
    padding-left: ${spacing.small};
  }
`

const Meta = styled.small`
  display: block;
  margin-top: ${spacing.medium};

  strong {
    color: ${greyDark};
  }
`

const normalizePrice = (val) => val.replace(/[^\d|.]/g, '')
const normalizeVolume = (val) => val.replace(/[^\d|.|-]/g, '')

const PricingCard = ({ submitPricingCapture }) => {
  const { translate } = useContext(LangContext)
  const { isMobile } = useIsMobile({ maxWidthInPixels: 600 })

  const { sectorId: customerId, upc } = useParams()
  const navigate = useNavigate()

  const [price, setPrice] = useState('')
  const [volume, setVolume] = useState('')
  const [outOfDistribution, setOutOfDistribution] = useState(false)
  const [validUpc, setValidUpc] = useState(true)

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER, { customerId })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const isMobileViewOnly = useSelector((state) => state.auth.user.isMobileViewOnly)
  const pricing = useSelector((state) => pricingSelectors.pricingFromUrl(state, { customerId, upc }))
  const nextCapture = useSelector((state) => pricingSelectors.nextCaptureUpc(state, { customerId, upc }))
  const previousCapture = useSelector((state) => pricingSelectors.previousCaptureUpc(state, { customerId, upc }))
  const capturesRemaining = useSelector((state) => pricingSelectors.capturesRemaining(state, { customerId, upc }))

  const isValidSubmit = Boolean(+price || outOfDistribution)
  const priceCapture = pricing.employeeCapture || pricing.retailerCapture

  const isDuplicateSubmit = useMemo(() => {
    const isSamePrice = (!price && !pricing?.price) || +price === +pricing?.price
    const isSameDistribution = Boolean(outOfDistribution) === Boolean(pricing?.outOfDistribution)
    const isSameVolume = (!volume && !pricing?.volume) || +volume === +pricing?.volume
    const lastSubmitTooRecent =
      priceCapture && priceCapture?.updatedAt && moment().diff(priceCapture?.updatedAt, 'hours') < 6
    return isSamePrice && isSameDistribution && isSameVolume && lastSubmitTooRecent
  }, [price, outOfDistribution, volume, pricing])

  const nextCaptureSlug = nextCapture ? `/${nextCapture}` : '/thank-you'

  const submitCapture = () => {
    if (!isValidSubmit || isMobileViewOnly) return

    if (!isDuplicateSubmit) {
      const body = {
        customerId: +customerId,
        upc: pricing.upc,
        price: +price || null,
        volume: +volume || null,
        outOfDistribution,
        competitorUpc: pricing.competitorUpc
      }

      submitPricingCapture(body)
    }

    navigate('..' + nextCaptureSlug)
  }

  const renderBarcode = (newUpc) => {
    const { format, upcString } = formatUpc(newUpc)
    JsBarcode('#barcode', upcString, {
      format,
      valid: setValidUpc
    })
  }

  useEffect(() => {
    if (pricing) {
      try {
        renderBarcode(pricing.upc)
      } catch (e) {
        console.error(e)
      } finally {
        setOutOfDistribution(pricing?.outOfDistribution || false)
        setPrice(pricing?.price || '')
        setVolume('')
      }
    }
  }, [pricing?.upc, pricing?.outOfDistribution])

  const handleOutOfDistribution = () => {
    const newOutOfDistribution = !outOfDistribution
    setOutOfDistribution(newOutOfDistribution)

    if (!newOutOfDistribution) {
      setPrice('')
    }
  }

  const handleClose = (e) => {
    e.stopPropagation()
    navigate('..')
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => isMobile && navigate(`../${nextCapture}`),
    onSwipedRight: () => isMobile && navigate(`../${previousCapture}`),
    delta: 10,
    preventScrollOnSwipe: false,
    trackTouch: true,
    trackMouse: true,
    rotationAngle: 0,
    swipeDuration: Infinity,
    touchEventOptions: { passive: true }
  })

  const getContent = () => {
    if (isLoading) return <WrappedSpinner icon="spinner" />

    return (
      <>
        <PricingCardNavArrow isPrev href={`../${previousCapture}`} />

        <div onClick={(e) => e.stopPropagation()}>
          <Inner capturesRemaining={capturesRemaining} {...swipeHandlers}>
            <Close to="..">
              <Icon icon="close" />
            </Close>

            {pricing ? (
              <>
                <CodeWrap>{validUpc && <Code id="barcode" />}</CodeWrap>
                <Name className="notranslate" translate="no">
                  {pricing.productName}
                </Name>
                <Description className="notranslate" translate="no">
                  {pricing.shortBrand}
                </Description>
                <SegmentControl
                  onChange={handleOutOfDistribution}
                  value={String(outOfDistribution)}
                  options={[
                    { label: translate('app.inDistribution'), value: 'false' },
                    { label: translate('app.outOfDistribution'), value: 'true' }
                  ]}
                />
                <Details>
                  <DetailsColumn>
                    <Input
                      dollar
                      disabled={outOfDistribution}
                      small
                      label={translate('common.priceBeforeTax')}
                      input={{
                        value: price,
                        onChange: ({ target }) => setPrice(normalizePrice(target.value))
                      }}
                    />
                    <Meta>
                      Ceiling: <strong>{pricing.priceCeiling}</strong>
                    </Meta>
                    <Meta className={cn(!pricing?.retailerCapture && 'line-through opacity-35')}>
                      Ret. PC: <strong>{pricing?.retailerCapture?.price || ''}</strong>
                    </Meta>
                  </DetailsColumn>
                  <DetailsColumn>
                    <Input
                      label={translate('pricingCard.labels.volume')}
                      small
                      input={{
                        value: volume,
                        onChange: ({ target }) => setVolume(normalizeVolume(target.value))
                      }}
                    />
                    <Meta>
                      Comp: <strong>{pricing.competitivePrice}</strong>
                    </Meta>
                    <Meta className={cn(!pricing?.retailerCapture && 'line-through opacity-35')}>
                      Ret. Comp: <strong>{pricing?.retailerCapture?.competitivePrice || ''}</strong>
                    </Meta>
                  </DetailsColumn>
                </Details>
                <Button onClick={submitCapture} disabled={!isValidSubmit || isMobileViewOnly} primary full>
                  {translate('common.submit')}
                </Button>
              </>
            ) : (
              <>
                <Name className="notranslate">{translate('common.notAvailable')}</Name>
                <Description>{translate('pricingCard.selectedUPCNotAvailable')}</Description>
                <Button onClick={() => navigate('..')} primary full>
                  {translate('common.close')}
                </Button>
              </>
            )}
          </Inner>
        </div>

        <PricingCardNavArrow href={`../${nextCapture}`} />
      </>
    )
  }

  return <PricingCardOverlay onClick={handleClose}>{getContent()}</PricingCardOverlay>
}

PricingCard.propTypes = {
  submitPricingCapture: func.isRequired
}

export default connect(null, { submitPricingCapture })(PricingCard)

export const ThankYouCard = () => {
  const { translate } = useContext(LangContext)
  const navigate = useNavigate()

  return (
    <Container>
      <Table>
        <Cell>
          <Inner>
            <Close to={'..'}>
              <Icon icon="close" />
            </Close>
            <Name>{translate('pricingCard.thankYou')}</Name>
            <Button onClick={() => navigate('..')} primary full>
              {translate('common.done')}
            </Button>
          </Inner>
        </Cell>
      </Table>
    </Container>
  )
}
