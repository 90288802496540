import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import config from 'config'
import { func, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'

import { createExtraDashboardToken } from 'store/auth/actions'
import { getExtraDashboardToken } from 'store/auth/selectors'

import ClickableTile from 'components/ClickableTile'

import { SECTOR_LEVELS } from 'utils/constants'
import { cn } from 'utils/styling'

const Container = tw.div`max-md:pt-20 gap-4`

const CloseIndex = ({ extraDashboardToken, createExtraDashboardTokenAction }) => {
  const { sectorType, sectorId } = useParams()
  const { translate, lang } = useContext(LangContext)

  useEffect(() => {
    if (sectorType === SECTOR_LEVELS.CUSTOMER) {
      createExtraDashboardTokenAction(sectorId, lang)
    }
  }, [sectorType, sectorId, lang])

  const TILES = [
    {
      title: translate('close.consumerOffer'),
      description: translate('close.consumerOffer.description'),
      to: 'consumer-offer',
      order: 1,
      comingSoon: false,
      disabled: sectorType === SECTOR_LEVELS.TERRITORY
    },
    {
      title: translate('close.i2a'),
      description: translate('close.i2a.description'),
      to: 'insight-to-action',
      order: 2,
      comingSoon: !config.featureFlags.i2a
    },
    {
      title: translate('close.swapcalculator'),
      description: translate('close.swapcalculator.description'),
      descriptionEnd: translate('close.swapcalculator.description.end'),
      href: `${
        config.extraDashboardUrl
      }/login/tm-callback?destination=swap-calculator&token=${extraDashboardToken}&return-url=${encodeURIComponent(
        window.location.href
      )}`,
      target: '_blank',
      order: 3,
      comingSoon: false,
      disabled: sectorType !== SECTOR_LEVELS.CUSTOMER
    }
  ]

  return (
    <Container>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        <ClickableTile
          content={{
            title: translate('close.closeOpportunities'),
            description: translate('close.closeOpportunities.description'),
            to: 'close-opportunities',
            disabled: sectorType !== SECTOR_LEVELS.CUSTOMER || !config.featureFlags.closeOpportunities
          }}
          comingSoon={!config.featureFlags.closeOpportunities}
        />
      </div>

      <div className={cn('mt-4 grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3')}>
        <hr className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4" />

        {TILES.map((tile) => (
          <ClickableTile key={tile.title} content={tile} comingSoon={tile.comingSoon} />
        ))}
      </div>
    </Container>
  )
}

CloseIndex.propTypes = {
  extraDashboardToken: string.isRequired,
  createExtraDashboardTokenAction: func.isRequired
}

const mapStateToProps = (state) => ({
  extraDashboardToken: getExtraDashboardToken(state)
})
export default connect(mapStateToProps, {
  createExtraDashboardTokenAction: createExtraDashboardToken
})(CloseIndex)
