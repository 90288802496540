import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, Outlet, useParams } from 'react-router-dom'
import moment from 'moment'

import LangContext from 'context/LangContext'

import { customerFromUrl } from 'store/customers/selectors'
import { customeRetailerPricing, nextCaptureUpc } from 'store/pricings/selectors'

import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import GlobalAlert from 'components/GlobalAlert'
import { SubHeader } from 'components/Header'
import PriceCaptureTable from 'components/PriceCaptureTable'
import Status from 'components/Status'
import TabNav from 'components/TabNav'

import { MIN_PRICE_UPDATE } from 'utils/constants'
import { formatCurrency } from 'utils/formatters'

import { primaryColor } from 'styles/colors'

function getCaptureDate({ status, updatedAt, price, outOfDistribution }) {
  if (!price && !outOfDistribution) return ''
  if (price && status === 'expired') return 'OUTDATED'
  return moment(updatedAt).parseZone().format('MM/DD/YY')
}

const Pricings = () => {
  const { customerId, upcs } = useParams()

  const pricings = useSelector((state) => customeRetailerPricing(state, { customerId }))
  const nextCapture = useSelector((state) => nextCaptureUpc(state, { customerId, upcs }))
  const customer = useSelector((state) => customerFromUrl(state, { customerId }))

  const { lang, setLang, translate } = useContext(LangContext)
  const [generatingPrintout, setGeneratingPrintout] = useState(false)

  useEffect(() => {
    let mounted = true

    async function main() {
      if (!mounted) return

      if (generatingPrintout) {
        const { printItcoPrices } = await import('utils/createPricePrintoutPdf')
        printItcoPrices(customer.id, pricings, setGeneratingPrintout)
      }
    }

    main()

    return () => {
      mounted = false
    }
  }, [generatingPrintout, customer.id, pricings])

  const allCapturesComplete = pricings.every(
    ({ price, outOfDistribution, updatedAt }) =>
      (price || outOfDistribution) && moment(updatedAt).isAfter(MIN_PRICE_UPDATE, 'day')
  )

  // const createPrintout = () => {
  //   setGeneratingPrintout(true)
  // }

  const links = [{ label: translate('common.pricing'), to: `pricing` }]
  const altLang = lang === 'en' ? 'fr' : 'en'

  return (
    <>
      <SubHeader
        color={primaryColor}
        title={customer.name}
        subtitle={customer.address.line1}
        headerFarRight={<button onClick={() => setLang(altLang)}>{altLang.toUpperCase()}</button>}
      />
      <TabNav links={links} />
      <Container padded>
        {!pricings.length && <EmptyState title="No price requests" subtitle="We have no pricing data." />}
        {pricings.length && (
          <>
            {allCapturesComplete && (
              <GlobalAlert success>{translate('retailer.pricing.allCapturesComplete')}</GlobalAlert>
            )}

            <ButtonGroup>
              <Button to={`${nextCapture}`} primary>
                {translate('pricing.updateAllPrices')}
              </Button>
              {/* <Button onClick={createPrintout} primary isLoading={generatingPrintout}>
                {translate('pricing.printPrices')}
              </Button> */}
            </ButtonGroup>

            <PriceCaptureTable
              captureLink={`${nextCapture}`}
              isRetailer
              allCapturesComplete={allCapturesComplete}
              pricings={pricings}
              extractKey={(pricing, index) => index}
              renderRow={(pricing) => (
                <>
                  <td className="notranslate" translate="no">
                    <Link
                      className="block w-fit rounded-md p-0.5 px-1.5 text-brand-800 hover:bg-brand-100 hover:text-brand-600"
                      to={pricing.upc}
                    >
                      {pricing.productName}
                    </Link>
                  </td>
                  <td className="notranslate">{pricing.shortBrand}</td>
                  <td>
                    <Status
                      status={pricing?.status || 'noStatus'}
                      label={pricing.outOfDistribution ? '-' : formatCurrency(pricing.price)}
                    />
                  </td>
                  <td>{getCaptureDate(pricing || {})}</td>
                </>
              )}
            />
            <Outlet />
          </>
        )}
      </Container>
    </>
  )
}

export default Pricings
