import React, { useContext, useEffect, useMemo } from 'react'
import { func, string } from 'prop-types'

import SectorContext from 'context/SectorContext'

// import SegmentControl from 'components/SegmentControl'
import Dropdown from 'components/Dropdown'

import { SECTOR_LEVELS } from 'utils/constants'

const BASE_DISPLAY_OPTS = [
  {
    label: 'Brand',
    value: 'brand'
  },
  {
    label: 'Brand Variant',
    value: 'variantLabel'
  },
  {
    label: 'Product',
    value: 'sku'
  }
]

const CUSTOMER_DISPLAY_OPTS = [
  {
    label: 'Store',
    value: 'customer'
  }
]

const TERRITORY_DISPLAY_OPTS = [
  {
    label: 'Territory',
    value: 'territory'
  },
  {
    label: 'KA Subregion',
    value: 'subregion'
  },
  {
    label: 'Head Office',
    value: 'headoffice'
  },
  {
    label: 'Store',
    value: 'customer'
  }
]

const DISTRICT_DISPLAY_OPTS = [
  {
    label: 'District',
    value: 'district'
  },
  ...TERRITORY_DISPLAY_OPTS
]

const REGION_DISPLAY_OPTS = [
  {
    label: 'Region',
    value: 'region'
  },
  {
    label: 'Province',
    value: 'province'
  },
  ...DISTRICT_DISPLAY_OPTS
]

export const displayOptTableLabels = REGION_DISPLAY_OPTS.concat(BASE_DISPLAY_OPTS).reduce(
  (acc, { value, label }) => ({
    ...acc,
    [value]: label
  }),
  {}
)

const DISPLAY_OPTS_BY_SECTOR = {
  [SECTOR_LEVELS.NATIONAL]: REGION_DISPLAY_OPTS,
  [SECTOR_LEVELS.REGION]: REGION_DISPLAY_OPTS,
  [SECTOR_LEVELS.DISTRICT]: DISTRICT_DISPLAY_OPTS,
  [SECTOR_LEVELS.TERRITORY]: TERRITORY_DISPLAY_OPTS,
  [SECTOR_LEVELS.CUSTOMER]: CUSTOMER_DISPLAY_OPTS
}

const TableRowHeaderToggle = ({ geography, setGeography, currentChannel }) => {
  const { selectedLevel, currentProductType } = useContext(SectorContext)
  const geographyOptions = useMemo(() => {
    if (!selectedLevel || !currentProductType) return []
    const displayOptsForSector = DISPLAY_OPTS_BY_SECTOR[selectedLevel]

    return displayOptsForSector
      .concat(BASE_DISPLAY_OPTS)
      .filter(({ value }) => currentChannel !== 'specialty' || value !== 'sku')
  }, [selectedLevel, currentProductType, currentChannel])

  useEffect(() => {
    if (!geographyOptions.some((opt) => opt.value === geography)) {
      setGeography(geographyOptions[0].value)
    }
  }, [selectedLevel, geographyOptions])

  return (
    <Dropdown
      key="amplify-sell-out-table-row-header-opts"
      name="geography"
      onChange={(e) => {
        setGeography(e.target.value)
      }}
      value={geography}
      options={geographyOptions}
    />
  )
}

TableRowHeaderToggle.propTypes = {
  geography: string,
  setGeography: func,
  currentChannel: string
}

export default TableRowHeaderToggle
